body > #root > div {
  height: 100vh;
}

body {
  margin: 0;
  font-family: "FHOscar-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131313;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "FH Oscar";
  src: url("./fonts/FHOscar-SemiBold.woff2") format("woff2"),
    url("./fonts/FHOscar-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  overflow-x: hidden;
}
